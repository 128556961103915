<template>
  <mi-table
    ref="miTable"
    :columns="columns"
    :filter="validationsSearchString"
    :loading="isBomValidationLoading"
    :rows="userBomValidations.filteredResults"
    class="bom-validations-table full-height q-px-lg"
    column-sort-order="da"
    loading-label="Loading validations. Please wait ..."
    no-data-label="No validations found"
    row-key="id"
    title="Validations"
    virtual-scroll-sticky-size-start="40"
    hide-pagination
    sticky-header
    striped
    virtual-scroll
    wrap-cells
  >
    <!-- Status column -->
    <template #body-cell-status="{ value, row }">
      <!-- Validation status -->
      <mi-td class="bom-validations-table__status-cell">
        <div class="flex items-center">
          <mi-badge class="text-uppercase q-mr-xs" :color="value.color">
            {{ value.title }}
          </mi-badge>

          <div class="q-ml-auto">
            <!-- Open validation button -->
            <mi-tr-action-btn
              v-show="value.title === RESULT_STATUS.SUCCESS.title"
              icon="exit"
              @click="openBomValidation(row)"
            >
              <mi-tooltip> Open </mi-tooltip>
            </mi-tr-action-btn>

            <!-- Delete validation button -->
            <mi-tr-action-btn
              v-show="value.title !== RESULT_STATUS.IN_PROGRESS.title"
              icon="trash"
              @click="openDeleteBomValidationDialog(row.id, row.productModelType)"
            >
              <mi-tooltip> Delete </mi-tooltip>
            </mi-tr-action-btn>
          </div>
        </div>
      </mi-td>
    </template>
  </mi-table>

  <!-- Delete validation dialog -->
  <validation-delete-dialog
    v-model="isDeleteValidationDialogShown"
    :delete-fn="deleteBOMValidation"
    :validation-id="bomValidationIdToDelete"
    :is-user-export-delete-from-daily="isUserExport"
    @delete:validation="handleBomValidationDeletedEvent"
  ></validation-delete-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import { deleteBOMValidation } from '@/api'
  import formatDate from '@/utils/formatDate'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { RESULT_STATUS, PM_TYPE } from '@/constants'
  import {
    SET_CURRENT_BOM_VALIDATION,
    SET_IN_PROGRESS_BOM_VALIDATIONS,
    SET_BOM_VALIDATION_IS_LOADING
  } from '@/store/modules/validations/mutationTypes'
  import { BOM_VALIDATION_RESULTS_ROUTE } from '@/router/routeNames'
  import {
    BOM_UPDATE_STATUS,
    BOM_VALIDATION_DELETE,
    BOM_VALIDATIONS_PAGE
  } from '@/utils/analytics/constants'
  import getters from '@/store/modules/validations/getters'
  import ValidationDeleteDialog from '@/components/validations/ValidationDeleteDialog.vue'
  import state from '@/store/modules/validations/state'
  import recordAnalyticsMulti from '@/utils/analytics/recordAnalyticsMulti'
  import { formatProductModel } from '@/components/validations/utils'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('validations')

  export default {
    name: 'BomValidationsTable',
    components: { ValidationDeleteDialog },
    data: () => ({
      RESULT_STATUS,
      deleteBOMValidation,
      areBomValidationsLoading: false,
      isDeleteValidationDialogShown: false,
      isUserExport: false,
      bomValidationIdToDelete: '',
      tempBomValidationsInProgress: [],
      columns: [
        {
          name: 'componentId',
          label: 'Component ID',
          align: 'left',
          field: 'componentId',
          classes: 'text-uppercase',
          sortable: true
        },
        { name: 'validator', label: 'Validator', align: 'left', field: 'user', sortable: true },
        {
          name: 'productModel',
          label: 'Product Model',
          align: 'left',
          field: (
            { productModelType, businessName, userExportsName } = {}
          ) => (
            { productModelType, businessName, userExportsName }
          ),
          format: formatProductModel,
          sortable: true
        },
        {
          name: 'planningPeriodRange',
          align: 'left',
          label: 'Planning Period (Start - Finish)',
          field: ({ planningPeriodRange: { from, to } = {} } = {}) => `
            ${ removePrefixFromPlanningPeriod(from) } - ${ removePrefixFromPlanningPeriod(to) }
          `,
          sortable: true
        },
        {
          name: 'validationDate',
          label: 'Validation Date (Start - Finish)',
          align: 'left',
          sortable: true,
          field: ({ startDate, endDate } = {}) => ({ startDate, endDate }),
          sort: (a, b) => new Date(a.startDate) - new Date(b.startDate),
          format: ({ startDate, endDate } = {}) => `
            ${ formatDate(startDate) } - ${ endDate ? formatDate(endDate) : '' }
          `
        },
        {
          name: 'status',
          label: 'Status',
          align: 'left',
          field: 'status',
          headerStyle: 'min-width: 144px; padding-left: .5rem; padding-right: .5rem',
          format: value => RESULT_STATUS[value] || {},
          sortable: true
        }
      ]
    }),
    computed: {
      ...mapState([
        'bomValidations',
        'validationsSearchString',
        'isBomValidationLoading',
        'userBomValidations'
      ])
    },
    async created() {
      await this.fetchBOMValidations()
    },

    beforeMount() {
      const updateValidationHeaderEvent = new CustomEvent('validations-header', { detail: true })
      document.dispatchEvent(updateValidationHeaderEvent)
      // Analytics
      recordAnalytics(BOM_VALIDATIONS_PAGE)
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.miTable?.sort('validationDate')
      })
    },
    methods: {
      ...mapActions(['getBomValidationsList']),
      ...mapMutations({ SET_CURRENT_BOM_VALIDATION, SET_IN_PROGRESS_BOM_VALIDATIONS, SET_BOM_VALIDATION_IS_LOADING }),

      async fetchBOMValidations() {
        this.SET_BOM_VALIDATION_IS_LOADING(true)

        try {
          this.checkBomValidationsStatus()
          await this.getBomValidationsList()
        }
        finally {
          this.SET_BOM_VALIDATION_IS_LOADING(false)
        }
      },
      checkBomValidationsStatus() {
        if (!state.bomValidationsInProgress?.length) return
        const changedStatus = getters.getChangedStatus(this.tempBomValidationsInProgress)
        if (changedStatus.length) recordAnalyticsMulti(BOM_UPDATE_STATUS, changedStatus)
      },
      handleBomValidationDeletedEvent() {
        this.bomValidationIdToDelete = ''
        this.fetchBOMValidations()

        // Analytics
        recordAnalytics(BOM_VALIDATION_DELETE)
      },
      openDeleteBomValidationDialog(validationId = '', productModelType = '') {
        this.bomValidationIdToDelete = validationId
        this.isUserExport = productModelType === PM_TYPE.USER_EXPORTS
        this.isDeleteValidationDialogShown = true
      },
      openBomValidation(validation = {}) {
        this.SET_CURRENT_BOM_VALIDATION(validation)
        const route = this.$router.resolve({
          name: BOM_VALIDATION_RESULTS_ROUTE.name,
          params: { id: validation.id }
        })
        if (route?.href) window.open(route.href, '_blank')
      }
    }
  }
</script>

<style lang="scss">
  .bom-validations-table .bom-validations-table__status-cell {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .table-cell-pm {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .pills {
    &__pm,
    &__ue {
      min-width: 16px;
      width: 16px;
      height: 8px;
      border-radius: 4px;
    }

    &__pm {
      background: #cbd3dc;
    }

    &__ue {
      background: #76b3de;
    }
  }

  .table-cell {
    &__text {
      word-wrap: break-word;
    }
  }
</style>
